.broken-background {
  position: absolute;
  left: -10vh;
  top: -10vh;
  max-height: min(40vh, 60vw);
  max-width: min(40vh, 60vw);
  z-index: -1;
}
.root {
  padding: 0.2rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  flex: 1;
  hr {
    margin-bottom: 1.5rem;
    margin-left: 0;
    border: 0.5px solid var(--palette-divider);
    width: 50%;
  }
  > div:nth-child(2) {
    align-items: center;
    > svg {
      max-height: 45vh;
      max-width: 45vh;
    }
    > h1 {
      font-size: 3rem;
      text-align: center;
    }
  }
  > div {
    position: relative;
    flex-basis: 17.5rem;
    flex: 1;
    > h1 {
      margin: 0;
    }
    margin: 1rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    code {
      color: var(--palette-text-secondary);
    }
    > nav {
      > a {
        color: var(--palette-accent-main);
        margin-bottom: 1rem;
      }
      display: flex;
      flex-direction: column;
    }
  }
}
