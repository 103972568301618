@import 'mixins';
html {
  overflow-x: hidden;
  overflow-y: scroll;

  --palette-primary-main: #495867;
  --palette-primary-contrast-text: #fff;

  --palette-secondary-main: #2bd675;
  --palette-secondary-contrast-text: #fff;
  --palette-secondary-background: #d2eddb;
  --palette-secondary-background-hover: #c6e4d0;

  --palette-accent-main: #2bd675;
  --palette-accent-contrast-text: #fff;

  --palette-interaction-main: #2bd675;
  --palette-interaction-contrast-text: #fff;

  --palette-error-main: #ff3366;
  --palette-error-background: #ffebeb;
  --palette-error-contrast-text: #7b0000;

  --palette-success-main: #2bd675;
  --palette-success-background: #f8f8fa;
  --palette-success-contrast-text: white;

  --palette-warning-main: #bc7100;
  --palette-warning-background: rgb(252, 247, 233);
  --palette-warning-contrast-text: #fff;

  --palette-info-main: #495867;
  --palette-info-background: #f8f8fa;
  --palette-info-contrast-text: #fff;

  --palette-background-default: #fff;
  --palette-background-secondary: #f8f8fa;
  --palette-background-hover: #e2e3ec;
  --palette-background-overlay: #cecfd88;

  --palette-divider: #e1e1e1;

  --palette-text-primary: #495867;
  --palette-text-default: #495867;
  --palette-text-secondary: #8d92a8;
  --palette-text-hint: #8d92a8;

  --palette-text-hint: #8d92a8;

  --palette-text-logo: #495867;

  --palette-survey-filter: #3399ff;

  --shadow-default: 0px 1px 5px rgba(150, 150, 150, 0.3);
  --shadow-hover: 0px 1px 10px 0px rgba(0, 0, 0, 0.15);
  --shadow-overlay: 0px 1px 5px rgb(106, 106, 106);

  --palette-transparent-overlay: rgba(255, 255, 255, 0.7);
  --palette-transparent-overlay-hover: rgba(255, 255, 255, 0.9);
  --palette-transparent-autofill: #f5fcf7;

  --dimensions-border-radius-card: 13px;
  --dimensions-border-radius-default: 4px;
  --dimensions-line-height-default: 1.2;

  --dimensions-font-size-small: 0.8rem;
  --dimensions-font-size-normal: 1rem;
  --dimensions-font-size-large: 1.5rem;
  --dimensions-font-size-xlarge: 2rem;
  --dimensions-font-size-xxlarge: 3rem;

  --animation-duration: 0.2s;

  --upper-header-height: 65px;
  --header-nav-height: 60px;
  --header-height: calc(var(--upper-header-height) + var(--header-nav-height));
  --header-transition-duration: 0.25s;

  --dimensions-font-londrina-size-normal: 1.9rem;
  --dimensions-font-londrina-size-large: 2rem;
  --dimensions-font-londrina-size-xlarge: 2.5rem;
  --dimensions-font-londrina-size-xxlarge: 3rem;

  // @media (prefers-color-scheme: dark) {
  //   --palette-primary-main: #1C2451;
  //   --palette-primary-contrast-text: #fff;

  //   --palette-background-default: #24282C;
  //   --palette-background-secondary: #1D2125;
  //   --palette-background-hover: #29292f;
  //   --palette-background-overlay: #8888;

  //   --palette-divider: #44484C;

  //   --palette-text-primary: #FFF;
  //   --palette-text-default: #BBB;
  //   --palette-text-secondary: #999;
  //   --palette-text-hint: #8D92A8;
  //   --palette-text-logo: white;

  //   --shadow-default: 0px 1px 5px #0004;
  //   --shadow-hover: 0px 1px 10px 0px #0004;
  //   --shadow-overlay: 0px 1px 5px #0024;
  // }
}
html[data-mobile-app='true'] {
  header {
    display: none;
  }
  footer {
    display: none;
  }
  .mobile-app-hidden {
    display: none;
  }
  body {
    padding-top: 0;
  }
  .footer-signup-link {
    display: none;
  }
}

html {
  .logged-in-only {
    display: none;
  }
  .logged-out-only {
    display: flex;
  }
  &[data-logged-in='true'] {
    .logged-in-only {
      display: flex;
    }
    .logged-out-only {
      display: none;
    }
  }
}

body {
  padding: 113px 0 0 0;
  margin: 0;
  font-family: 'Metropolis', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial,
    sans-serif;
  scroll-behavior: smooth;
  background-color: var(--palette-background-default);
  color: var(--palette-text-default);
  font-size: 1rem;
}

a {
  color: inherit;
  color: var(--palette-interaction-main);
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis-Regular'), url(/fonts/Metropolis-Regular.woff2);
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis-Medium'), url(/fonts/Metropolis-Medium.woff2);
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis-SemiBold'), url(/fonts/Metropolis-SemiBold.woff2);
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis-Bold'), url(/fonts/Metropolis-Bold.woff2);
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis-ExtraBoldItalic'), url(/fonts/Metropolis-ExtraBoldItalic.woff2);
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis-RegularItalic'), url(/fonts/Metropolis-RegularItalic.woff2);
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis-Black'), url(/fonts/Metropolis-Black.woff2);
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Londrina';
  src: local('Londrina-Solid'), url(/fonts/Londrina-Solid.woff2);
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  text-decoration: none;
  font-family: inherit;
  cursor: pointer;
  background: inherit;
  font-size: var(--dimensions-font-size-normal);
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
button,
input,
select,
label,
li,
table {
  font-family: 'Metropolis', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial,
    sans-serif;
  letter-spacing: -0.03em;
  padding: 0;
}
p {
  margin: 0.8rem 0 0.8rem 0;
  line-height: 1.4;
}
h1 {
  font-size: var(--dimensions-font-size-xxlarge);
  margin: 0 0 0.8rem 0;
}
h2 {
  font-size: var(--dimensions-font-size-xlarge);
  margin: 2rem 0 0.8rem 0;
  font-weight: 600;
}
h3 {
  font-size: var(--dimensions-font-size-large);
  margin: 2rem 0 0.8rem 0;
  font-weight: 600;
}
h4,
h5,
h6 {
  font-size: var(--dimensions-font-size-normal);
  margin: 1.5rem 0 0.8rem 0;
}
h6 {
  font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
h7 {
  color: var(--palette-text-primary);
  line-height: 1.2;
}

.fadedPageBg {
  background-image: linear-gradient(rgba(232, 248, 239, 1) 0px, rgba(255, 255, 255, 1) 150px);
}
