@import 'styles/mixins';

.root {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-style: italic;
  margin-top: 12px;

  p {
    margin: 0;
  }

  @include md {
    padding-top: 16px;
  }
}

.logoContainer {
  width: 70px;
  display: flex;
  margin-left: 10px;
  @include md {
    width: 90px;
  }
}
