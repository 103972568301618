@import 'styles/mixins';

.footer {
  background-color: var(--palette-primary-main);
  height: auto;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  z-index: 1;
}
.footerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include sm {
    max-width: 88rem;
    display: flex;
    flex-direction: row;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    & li {
      padding: 0.5rem 0;
    }
    & a {
      text-decoration: none;
      color: var(--palette-primary-contrast-text);
      font-size: 0.85rem;
    }
  }
}

.socialLinks {
  padding: 0.5rem 0;
}
.footerColumn {
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
  @include sm {
    padding: 3.5rem 0;
    text-align: left;
    margin: 0;
  }
}
.footerBaseLine {
  border-bottom: none;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  background: none;
  margin: 0;
}
.footerBase {
  color: var(--palette-primary-contrast-text);
  padding-top: 40px;
  padding-bottom: 60px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: auto;
  font-size: 0.85rem;
}
.left {
  display: none;
  @include sm {
    display: block;
    text-align: left;
    float: left;
  }
}
.right {
  margin: auto;
  text-align: center;
  float: none;
  @include sm {
    margin: 0;
    text-align: right;
    float: right;
  }
  a {
    color: var(--palette-primary-contrast-text);
    text-decoration: none;
  }
}
.columnTitle {
  color: var(--palette-primary-contrast-text);
  font-weight: 500;
  @include londrina-font;
}

.signUpCtaLinkContainer {
  display: flex;
  justify-content: center;

  @include sm {
    display: flex;
  }
}

:global(.hideFooterCta) .signUpCtaLinkContainer {
  display: none;
}

$signUpCtaLinkHeight: 70px;

.signUpCtaLink {
  display: flex;
  background-color: var(--palette-secondary-main);
  color: var(--palette-secondary-contrast-text);
  height: $signUpCtaLinkHeight;
  align-items: center;
  padding: 10px 40px 4px;
  border-radius: ($signUpCtaLinkHeight - 20) ($signUpCtaLinkHeight - 20) 0 0;
  transform: translateY(4px);
  transition: transform 350ms;
  z-index: 0;
  text-align: center;

  @include sm {
    padding-left: 100px;
    padding-right: 100px;
  }

  &:hover {
    transform: translateY(0);
  }
}
