.small {
  font-size: var(--dimensions-font-size-small);
}
.normal {
  font-size: var(--dimensions-font-size-normal);
}
.large {
  font-size: var(--dimensions-font-size-large);
}
.xlarge {
  font-size: var(--dimensions-font-size-xlarge);
}
.xxlarge {
  font-size: var(--dimensions-font-size-xxlarge);
}
.primary {
  color: var(--palette-text-primary);
}
.secondary {
  color: var(--palette-text-secondary);
}
.default {
  color: var(--palette-text-default);
}
.bold {
  font-weight: bold;
}

.markdown {
  li {
    margin: 0.8rem 0;
    line-height: 1.3;
  }
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
}
