@import 'styles/mixins';

.root {
  z-index: 46;
  position: fixed;
  padding: 20px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-color: var(--palette-divider);
  border-width: 1px;
  border-style: solid;
  background-color: var(--palette-background-secondary);
  opacity: 1;
  width: 100%;
  animation-duration: 350ms;
  animation-name: slidein;

  @include sm {
    width: auto;
  }
}
.layout {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.closeBtn {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 30px;
  height: 30px;
  background: var(--palette-background-hover);

  svg {
    fill: var(--palette-text-secondary);
    width: 12px;
    height: 12px;
    margin-top: 2px;
  }
}

@keyframes slidein {
  from {
    opacity: 0;
    top: -30px;
  }

  to {
    opacity: 1;
    top: 0;
  }
}
