@import 'styles/mixins';

.root {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  .link {
    font-weight: 600;
    color: var(--palette-text-primary);
    opacity: 1;
    font-size: var(--dimensions-font-size-small);
    transition: opacity var(--animation-duration);
    &:hover {
      opacity: 0.5;
    }
    @include sm {
      font-size: var(--dimensions-font-size-normal);
      display: block;
    }
  }

  .headerBtn {
    font-size: 0.875rem;
    padding: 0.7rem 1.2rem;
    margin-left: 10px;

    &:first-of-type {
      margin-left: 0;
    }
  }
}
.startIcon {
  transform: rotate(180deg);
  margin-left: 0.25rem;
}
