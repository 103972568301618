$screen-sm-min: 37.5rem;
$screen-md-min: 60rem;
$screen-lg-min: 80rem;
$screen-xl-min: 120rem;

@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin container-full-width {
  width: 100%;
  max-width: 1408px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;

  @include sm {
    padding-left: 32px;
    padding-right: 32px;
  }
  @include md {
    padding-left: 64px;
    padding-right: 64px;
  }
}

@mixin londrina-font {
  font-family: 'Londrina';
  font-weight: 400;
  letter-spacing: 0;
}

// $size options: xsmall, small, large, xlarge
@mixin londrina-title($size) {
  @include londrina-font;

  span,
  strong {
    color: var(--palette-secondary-main);
  }

  @if $size == 'xsmall' {
    font-size: var(--dimensions-font-londrina-size-normal);
    @include md {
      font-size: var(--dimensions-font-londrina-size-large);
    }
  }

  @if $size == 'small' {
    font-size: var(--dimensions-font-londrina-size-large);
    @include md {
      font-size: var(--dimensions-font-londrina-size-xlarge);
    }
  }

  @if $size == 'large' {
    font-size: var(--dimensions-font-londrina-size-xlarge);
    @include md {
      font-size: var(--dimensions-font-londrina-size-xxlarge);
    }
  }

  @if $size == 'xlarge' {
    font-size: 2.5rem;
    line-height: 1.15;

    @include md {
      font-size: 3.2rem;
    }

    @include lg {
      font-size: 4.3rem;
    }
  }
}
