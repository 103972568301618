@import 'styles/variables';

.root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  > * {
    align-self: stretch;
  }
  > .wrapper {
    position: relative;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &:global(.isLandingPage) {
    margin-top: -$navigation-height;
  }
}
