.root {
  padding: 12px 16px;
  background-color: var(--palette-background-default);
  box-shadow: var(--shadow-default);
  max-width: 900px;
  display: flex;
  align-items: center;
  z-index: 40;
  border-radius: var(--dimensions-border-radius-default);
  &.info {
    background-color: var(--palette-info-background);
    border: solid 1px var(--palette-info-main);
    color: var(--palette-info-main);
    p {
      color: var(--palette-info-main);
    }
    svg {
      fill: var(--palette-info-main);
    }
  }
  &.success {
    background-color: var(--palette-success-background);
    border: solid 1px var(--palette-success-main);
    color: var(--palette-success-contrast-main);
    p {
      color: var(--palette-success-main);
    }
    svg {
      fill: var(--palette-success-main);
    }
  }
  &.error {
    background-color: var(--palette-error-background);
    border: solid 1px var(--palette-error-main);
    color: var(--palette-error-main);
    p {
      color: var(--palette-error-main);
    }
    svg {
      fill: var(--palette-error-main);
    }
  }
  &.warning {
    background-color: var(--palette-warning-background);
    border: solid 1px var(--palette-warning-main);
    color: var(--palette-warning-main);
    p {
      color: var(--palette-warning-main);
    }
    svg {
      fill: var(--palette-warning-main);
    }
  }
  & > svg:first-child {
    margin-right: 1rem;
  }
  & > button {
    margin-left: 1rem;
    display: flex;
  }
  & p {
    flex: 1;
    margin: 0;
    line-height: 1.2em;
  }
}
.infoIcon {
  width: 30px;
  height: 30px;
}
.closeIcon {
  width: 15px;
  height: 15px;
}
.successIcon {
  width: 30px;
  height: 30px;
}
.errorIcon {
  width: 30px;
  height: 30px;
}
