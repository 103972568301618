@import 'styles/mixins';

.root {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  @include container-full-width;
}

.topBottomPadding {
  padding-top: 16px;
  padding-bottom: 16px;

  @include sm {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  @include md {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
