@import 'styles/mixins';
@import 'styles/variables';

.root {
  position: relative;
  border-bottom: solid 1px var(--palette-divider);
  height: $navigation-height;
  transition: all 0.25s ease-in;
  overflow: hidden;
}

:global(.scrollingDown) .root {
  margin-top: $navigation-margin-top-when-hidden;
}

:global(.isLandingPage) {
  &:global(.noScroll) {
    .root {
      margin-top: $navigation-margin-top-when-hidden;
    }
  }
}

:global(.isLandingPage) {
  &:global(.scrollingDown) {
    .root {
      margin-top: 0;
    }
  }
}

.content {
  display: flex;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  justify-content: flex-start;
  height: $navigation-height;
  @include container-full-width;

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;

  // This ::after extends the <nav> element so the last nav child
  // is not hidden behind the scroll fade element on mobile
  &::after {
    display: inline-block;
    height: $navigation-height;
    padding-right: 80px;
    content: '';
  }
}

.link {
  &:first-child {
    margin-left: 0;
  }
  &:hover {
    color: var(--palette-text-primary);
    span {
      width: 20px;
    }
    &::after {
      width: 20px;
      content: '';
    }
  }
  p {
    font-weight: 700;
    height: 0;
    visibility: hidden;
    margin: 0;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 1rem;
  color: var(--palette-text-primary);
  margin: 0 15px;
  padding: 0;
  text-decoration: none;
  position: relative;
  white-space: nowrap;
  transition: font-weight var(--animation-duration);

  &::after {
    display: block;
    height: 6px;
    width: 0px;
    background-color: var(--palette-accent-main);
    position: absolute;
    bottom: 0px;
    left: 0;
    content: '';
    transition: width var(--animation-duration);
  }
  &.activeLink,
  &:hover {
    color: var(--palette-secondary-primary);
    font-weight: 600;

    &::after {
      width: 20px;
    }
  }
}

html:not([data-logged-in='true']) .content > .link:global(.logged-in-only) + .link {
  margin-left: 0;
}

.sideScrollFade {
  position: absolute;
  right: -1px;
  bottom: 2px;
  top: 0;
  background: linear-gradient(
    270deg,
    var(--palette-background-default) 20%,
    rgba(255, 255, 255, 0) 100%
  );
  pointer-events: none;
  transition: width 2s;
}
.hiddenTabs0 {
  width: 50px;
}
.hiddenTabs1 {
  width: 100px;
}
.hiddenTabs2 {
  width: 105px;
}
