.root {
  color: var(--palette-divider);
}
.fill {
  flex: 1;
  align-items: center;
  justify-content: center;
  align-self: center;
  display: flex;
}
