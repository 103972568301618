@import 'styles/mixins';
@import 'styles/brands/TheSun/theSunVariables';
@import 'styles/variables';

.root {
  background-color: var(--palette-background-default);
  flex-direction: column;
  display: flex;
  position: relative;
  color: var(--palette-text-primary);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 45;

  a {
    text-decoration: none;
  }

  .upperHeaderContainer {
    width: 100%;
    display: flex;
    padding: 8px 0;
    height: var(--upper-header-height);
    box-shadow: var(--shadow-default);
    z-index: 2;
    background-color: var(--palette-background-default);
    position: relative;
    > nav {
      width: 100%;
      align-items: center;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
    }
  }

  .upperHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    @include container-full-width;
  }

  &.isExternalBrand {
    @include md {
      border-top-width: 11px;
      border-top-style: solid;
      border-top-color: $brand-palette-primary-main;
    }

    .upperHeaderContainer {
      height: 83px;
      align-items: flex-start;

      @include md {
        height: var(--upper-header-height);
      }
    }
  }
}

.logo {
  display: inline-block;
  vertical-align: top;
  flex-basis: 95px;
  width: 95px;
  color: var(--palette-text-logo);
}

.headerTagContainer {
  max-height: 0px;
  align-items: center;
  z-index: 1;
  display: none;

  @include sm {
    align-items: flex-start;
  }
}

:global(.isLandingPage) .headerTagContainer {
  display: flex;
}

.headerTag {
  background: var(--palette-secondary-main);
  color: white;
  padding: 2px 12px 0;
  margin-top: -46px;
  width: auto;
  height: 36px;
  line-height: 36px;
  transition: all 0.25s ease-in;
  font-size: 0.9rem;
  font-weight: 400;
  border-radius: 0 0 10px 10px;

  @include sm {
    font-size: 1rem;
    padding: 2px 16px 0;
    height: 44px;
    line-height: 44px;
  }
}

:global(.isLandingPage) {
  &:global(.noScroll) {
    .headerTag {
      margin-top: -3px;

      &:hover {
        margin-top: 0;
      }
    }
  }
}
